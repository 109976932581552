<template>
  <div style="min-height: 400px">
    <ScreenSelect
      @changeTimeRange="selectTime"
      :typeOptions="typeOptions"
      @changeType="changeType"
    />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { deviceAlarmList } from '@/services/device-manage/device';
import { deviceTypeEnum } from '@/enum/device';
import ScreenSelect from '@/views/safety-device/component/screen-select';
import { deviceTypeList } from '@/services/device-manage/device-detail';

@Component({
  components: {
    ScreenSelect,
  },
})
export default class DeviceWarning extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;

  @Watch('typeOptions', { deep: true })
  changeTypeList(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.typeOptions = newVal;
    }
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '报警时间',
        dataIndex: 'alarmTime',
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: '事件类型',
        dataIndex: 'subType',
        ellipsis: true,
        customRender: txt => (txt ? this.changeSubType(txt) : ''),
      },
      // {
      //   align: 'left',
      //   title: this.$t('detail.alarmEvent'),
      //   dataIndex: 'reason',
      //   ellipsis: true,
      // },
    ];
  }
  changeSubType(val) {
    const typeList = this.typeOptions;
    const idx = typeList.findIndex(v => v.value === val);
    return typeList[idx].label;
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  tableData = [];
  mounted() {
    this.getTableList();
    this.getTypeList();
  }
  typeOptions = [];
  async getTypeList() {
    try {
      this.typeOptions = await deviceTypeList(this.deviceGroup);
    } catch {
      return false;
    }
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  rangeTime = {
    startTime: '',
    endTime: '',
  };
  selectTime(t) {
    if (t && t.startTime) {
      this.rangeTime.startTime = t.startTime;
      this.rangeTime.endTime = t.endTime;
    } else {
      this.rangeTime = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  selectType = {
    type: '',
    value: '',
  };
  changeType(v) {
    if (v && v.value !== 'all') {
      this.selectType = v;
    } else {
      this.selectType = {
        type: '',
        value: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  loading = false;
  async getTableList() {
    const params = {
      deviceId: this.deviceId,
      query: {
        current: this.pagination.current,
        size: this.pagination.pageSize,
      },
      deviceGroup: this.deviceGroup,
    };
    if (this.rangeTime.startTime) {
      params.startTime = this.rangeTime.startTime;
      params.endTime = this.rangeTime.endTime;
    }
    if (this.selectType.value) {
      params.type = this.selectType.type;
      params.subType = this.selectType.value;
    }
    this.loading = true;
    try {
      const { records, current, size, total } = await deviceAlarmList(
        params,
        this.deviceGroup,
      );
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>

<style lang="less" scoped>
.buttonGroups {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--primary);
  cursor: pointer;
  .red {
    color: red;
  }
  .button {
    width: auto;
    position: relative;
  }
}
</style>
